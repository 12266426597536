import { AxiosResponse } from 'axios';
import { AuthModel } from './_models';
import { store } from '../../../../redux-store/store';
import { removeUser } from '../../../../redux-store/slices/userSlice';
import { persistStore } from 'redux-persist';
import { removeLanguages } from '../../../../redux-store/slices/languageSlice';
const baseUrl = process.env.REACT_APP_BASE_URL;
const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = () => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem('token')
  if (!lsValue) {
    return
  }

  try {
    const auth: any = lsValue
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

let isRefreshing = false;
let refreshSubscribers: Array<(token: string) => void> = [];

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      const auth = localStorage.getItem('token')
      if (auth) {
        config.headers.Authorization = `Bearer ${auth}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {
      const originalRequest = error.config;

      if (error.response && error.response.status === 401) {

        if (originalRequest.url.includes('/auth/refresh')) {

          localStorage.removeItem('token');

          persistStore(store).purge();

          store.dispatch(removeUser());
          store.dispatch(removeLanguages())


          return Promise.reject(error);
        }

        if (!isRefreshing) {
          isRefreshing = true;

          try {
            const response: AxiosResponse<any> = await axios.post(`${baseUrl}/api/auth/refresh`);
            const newToken = response.data.authorisation.token;

            localStorage.setItem('token', newToken);

            originalRequest.headers['Authorization'] = `Bearer ${newToken}`;

            return axios(originalRequest);
          } catch (refreshError) {

            localStorage.removeItem('token');
            persistStore(store).purge();
            store.dispatch(removeUser());

            return Promise.reject(refreshError);
          } finally {
            isRefreshing = false;
          }
        } else {
          return new Promise((resolve) => {
            refreshSubscribers.push((token: string) => {
              originalRequest.headers['Authorization'] = `Bearer ${token}`;
              resolve(axios(originalRequest));
            });
          });
        }
      }

      // Return other errors
      return Promise.reject(error);
    }
  );
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY }
