import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { getUserByToken, login } from '../core/_requests'
import { useDispatch } from 'react-redux';
import { setUser } from '../../../../redux-store/slices/userSlice'
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify'


const initialValues = {
  email: '',
  password: '',
}


export function Login() {

  const intl = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: "WRONG_EMAIL_FORMAT" }))
      .min(3, `${intl.formatMessage({ id: 'AUTH.VALIDATION.MIN_LENGTH_FIELD' })}3`)
      .max(50, `${intl.formatMessage({ id: 'AUTH.VALIDATION.MAX_LENGTH_FIELD' })}50`)
      .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })),
    password: Yup.string()
      .min(8, `${intl.formatMessage({ id: 'AUTH.VALIDATION.MIN_LENGTH_FIELD' })}8`)
      .max(50, `${intl.formatMessage({ id: 'AUTH.VALIDATION.MAX_LENGTH_FIELD' })}50`)
      .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await login(values.email, values.password)

        localStorage.setItem('token', auth.authorisation.token)
        const { data: user } = await getUserByToken(auth.authorisation.token);

        dispatch(setUser(user?.admin));
        setSubmitting(false);
        setLoading(false)



      } catch (error) {
        toast.error(error?.message)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>{intl.formatMessage({ id: 'AUTH.LOGIN.TITLE' })}</h1>
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })}</label>
        <input
          placeholder={intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' })}</label>
        <input
          type='password'
          placeholder={intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' })}
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'AUTH.LOGIN.BUTTON' })}</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              {intl.formatMessage({ id: 'LOADING' })}...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div> */}
    </form>
  )
}
