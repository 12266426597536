import { FC, createContext, useContext } from 'react'
import { WithChildren } from '../helpers'
import { useSelector } from 'react-redux'

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

const initialState: string = 'az'

function getConfig(l: string) {
  const ls = l
  if (ls) {
    try {
      return ls;
    } catch (er) {
      console.error(er)
    }
  }
  return initialState
}



if (!localStorage.getItem(I18N_CONFIG_KEY)) {
  localStorage.getItem(I18N_CONFIG_KEY)
}

const I18nContext = createContext<string>(initialState)


const useLang = () => {
  return useContext(I18nContext);
}

const TurniketI18nProvider: FC<WithChildren> = ({ children }) => {

  const selectedLanguage = useSelector((state: any) => state.persistedReducer.languages.currentLang);
  const lang = getConfig(selectedLanguage);
  
  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>
}

export { TurniketI18nProvider,useLang }
