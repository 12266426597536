import { FC, lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_turniket/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { getCSSVariableValue } from '../../_turniket/assets/ts/_utils'
import { WithChildren } from '../../_turniket/helpers'

const PrivateRoutes = () => {
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'));
  const LanguagesPage = lazy(() => import('../modules/apps/language-management/LanguagesPage'));
  const PermisionsPage = lazy(() => import('../modules/apps/permission-management/PermisisonsPage'));
  const RolesPage = lazy(() => import('../modules/apps/role-management/RolesPage'));
  const SettingsPage = lazy(() => import('../modules/apps/setting-management/SettingsPage'));
  const AdminsPage = lazy(() => import('../modules/apps/admin-management/AdminsPage'));


  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Lazy Modules */}

        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        <Route
          path='reports/information/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />


        <Route
          path='languages/*'
          element={
            <SuspensedView>
              <LanguagesPage />
            </SuspensedView>
          }
        />

        <Route
          path='permissions/*'
          element={
            <SuspensedView>
              <PermisionsPage />
            </SuspensedView>
          }
        />

        <Route
          path='roles/*'
          element={
            <SuspensedView>
              <RolesPage />
            </SuspensedView>
          }
        />

        <Route
          path='settings/*'
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />

        <Route
          path='admins/*'
          element={
            <SuspensedView>
              <AdminsPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      {children}
    </Suspense>
  )
}

export { PrivateRoutes }
