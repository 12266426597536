import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { useIntl } from 'react-intl';

export function AsideMenuMain() {

  const intl = useIntl();

  return (
    <>
      <AsideMenuItemWithSub
        to='/reports'
        title={intl.formatMessage({ id: 'REPORTS' })}
        fontIcon='bi-archive'
        icon='black-right'
      >
        <AsideMenuItem to='/reports/information/all-input-output-information' title={intl.formatMessage({ id: 'ALL_INPUT_OUTPUT_INFOS' })} hasBullet={true} />
        <AsideMenuItem to='/reports/information/delay-reports' title={intl.formatMessage({ id: 'DELAY_WORK' })} hasBullet={true} />
        <AsideMenuItem to='/reports/information/report-for-returning-late-from-dinner' title={intl.formatMessage({ id: 'LUNCH_DELAY' })} hasBullet={true} />
        <AsideMenuItem to='/reports/information/report-due-to-work-period' title={intl.formatMessage({ id: 'AT_WORK_REPORT' })} hasBullet={true} />
        <AsideMenuItem to='/reports/information/list-of-the-working-group' title={intl.formatMessage({ id: 'USER_LIST' })} hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem to='/languages' title={intl.formatMessage({ id: 'LANGUAGES' })} hasBullet={true} />
      <AsideMenuItem to='/permissions' title={intl.formatMessage({ id: 'PERMISSIONS' })} hasBullet={true} />
      <AsideMenuItem to='/roles' title={intl.formatMessage({ id: 'ROLES' })} hasBullet={true} />
      <AsideMenuItem to='/settings' title={intl.formatMessage({ id: 'SETTINGS' })} hasBullet={true} />
      <AsideMenuItem to='/admins' title={intl.formatMessage({ id: 'ADMINS' })} hasBullet={true} />
    </>
  )
}
